@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

:is(h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote, address) {
  margin: 0;
  padding: 0;
}

a,
button {
  text-decoration: none;
}

body {
  font-family: "Montserrat", Helvetica, Arial, serif;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* user page design */

/* .home {
  height: 100vh;
} */

.home .container-fluid {
  background-color: #FFF;
  color: #000;
}

.home .container-fluid .col-lg-4,
.col-xl-4,
.col-md-4 {
  padding: 0;
}

/* .modalImgDiv .home-model {
  height: 100vh !important;
  width: 100%;
  object-fit: cover;
  object-position: top;
} */

/* .selection-section {
  height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 15px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */


.selection-section .header .dropdown-menu {
  min-width: 11rem;
  text-align: left;
}

.selection-section .header .dropdown-item {
  width: 100%;
  padding: 0.3rem 1.0rem;
  float: left;
}

.selection-section .header .title {
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #9E0022;
  margin-bottom: 0;
}

.offline-title{
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #9E0022;
  margin-bottom: 0;
}

.scroll-more-title{
  font-size: 14px;
  color: #000;
  cursor: pointer;
}

html:lang(es) .selection-section .header .title{
  font-size: 22px;
}

html:lang(fr) .selection-section .header .title{
  font-size: 20px;
}

html:lang(ru) .selection-section .header .title{
  font-size: 16px;
}

/* .selection-section .description {
  font-size: 12px;
  white-space: break-spaces;
  padding: 0px 10px;
} */

/* .selection-section .question {
  background-color: #9E0022;
  text-align: center;
  padding: 1.5px;
  margin-top: 10px;
} */

/* .selection-section .answers {
  padding: 0px 20px 0px 20px;
  height: 48vh;
  overflow-y: auto;
} */

.selection-section .answers .ans-box {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 50px;
  margin-bottom: 15px;
  padding: 12px 0px 12px 20px;
}

/* .selection-section .answer-list {
  font-size: 12px;
} */

/* .selection-section .answers .ans-box-type1 {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  border-radius: 50px;
  margin-bottom: 15px;
} */

/* .selection-section .answers .inner-ans {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
} */

/* .selection-section .answers .inner-ans .type-img {
  width: 48px;
  border: 1px solid #000;
  border-radius: 50px;
  margin-right: 10px;
} */

/* .selection-section .question h4 {
  font-size: 1.286rem;
  color: #FFF;
} */

/* .selection-section footer p {
  margin: 0;
} */

.sm-text {
  font-size: 12px;
  color: #131313;
}

.footer-text {
  color: #000 !important;
  font-weight: 600;
  font-size: 14px !important;
  padding: 0;
  margin: 5px 0 2px 0;
}

.rotate-img {
  rotate: 180deg;
}

.img-detail-box {
  width: 100%;
  height: calc(100vh - 250px);
}

.img-detail-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.all-ans-data .col-6 {
  padding: 0;
}

/* Sachin's Css  */

html,
body,
#root,
.home,
.container-fluid,
.row {
  /* height: 100%; */
}

footer {
  width: 100%;
  margin-top: auto;
  cursor: pointer;
}

.show-small-imgs {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  /* max-height: calc(100vh - 42vh); */ 
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* height: calc(100vh - 41vh); */
  overflow-y: auto;
  overflow-x: hidden;
}

.hairComb-row {
  row-gap: 10px;
}

.rightSide-img {
  justify-content: flex-end;
  cursor: pointer;
}

.leftSide-img {
  justify-content: flex-start;
  cursor: pointer;
}

.center-img {
  justify-content: center;
  cursor: pointer;
}

.center-img .hairComb-width {
  width: calc(100% - 40%);;
}

.hairComb-width {
  width: 230px;
}

.leftSide-img .hairComb-width {
  margin-left: -60px;
}

.rightSide-img .hairComb-width {
  margin-right: -60px;
}


.hairComb-width img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modalImgDiv {
  background-color: hsla(0, 0%, 83%, 1);
  height: 100%;
}

.modalImgDiv .home-model {
  width: 100%;
  min-height: 100%;
  max-height: 100vh;
  max-height: 100svh;
  max-height: 100dvh;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.main-contain {
  position: relative;
}

.main-contain .answer-listDiv {
  padding: 15px 0px;
}

.selection-section {
  /* height: 100vh;
  width: 100%; */
  min-height: 100vh;
  min-height: 100svh;
  min-height: 100dvh;
  align-items: center;
  text-align: center;
  padding: 0px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow: hidden;
}

.selection-section > div {
  height: 100%;
}

.selection-section .header .demoElement {
  width: 56px;
  margin-bottom: 0;
  height: auto;
}

.selection-section .header .demoElement svg {
  cursor: pointer;
}


/* .changeLang .dropdown-menu.show {
  border: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  display: grid;
  gap: 6px;
  min-width: 286px;
  left: 20px !important;
  position: relative;
} */

.changeLang .dropdown-menu.show {
  border: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  display: grid;
  gap: 6px;
  min-width: 286px;
  position: relative;
}

.changeLang .dropdown-menu.show:dir(ltr) {
  right: 20px !important;
}

.arabic.changeLang .dropdown-menu.show {
  transform: translate(20px, 42px) !important;
}

.dropdown-item:active{
  background-color: #9E0022!important;
}

.dropdown-item:active span{
  color: #FFF!important;
}

.changeLang .dropdown-menu.show span {
  font-weight: 500;
  font-size: 17px;
  color: #242424;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.changeLang .dropdown-menu p {
  border-bottom: 1px solid #0000002b;
  margin-bottom: 0;
  padding-bottom: 14px;
}

.changeLang .dropdown-menu .dropdown-item {
  padding: 10px 30px;
  display: flex;
  align-items: center;
}

.selection-section .header {
  padding-block: 18px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  margin-bottom: 15px;
}


.selection-section .header .dropdown-item .flg-img {
  width: 28px;
  margin-right: 15px;
}


.selection-section .description {
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #131313;
  padding: 0 10px;
  margin-bottom: 20px;
  white-space: break-spaces;
}

html:lang(es) .selection-section .description {
  font-size: 12px;
}

html:lang(fr) .selection-section .description {
  font-size: 12px;
}

html:lang(ru) .selection-section .description {
  font-size: 11px;
}

.selection-section .question {
  background-color: #9E0022;
  text-align: center;
  padding: 4px 0 4px;
}


.selection-section .question h4 {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 0;
}

.selection-section .answers {
  width: min(100%, 500px);
  margin-inline: auto;
  display: grid;
  gap: 20px;
  margin-top: 0;
  padding: 0 30px 20px;
  max-height: calc(100vh - 354px);
  max-height: calc(100svh - 354px);
  max-height: calc(100dvh - 354px);
  overflow-y: auto;
}

.selection-section .answers .selectHairCareOptionBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 41px;
  border: 1px solid #000000;
  min-height: 60px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  isolation: isolate;
  margin: 0;
  transition:all 0.3s ease-in-out;
}

.selection-section .answers .selectHairCareOptionBtn:dir(rtl){
  padding: 0px 0px 0px 20px;
}

.selection-section .answers .selectHairCareOptionBtn:not(:dir(rtl)){
  padding: 0px 20px 0px 0px;
}

.selection-section .answers .selectHairCareOptionBtn:hover {
  background: #d5d5d570;
}

/* .selection-section .answers .selectHairCareOptionBtn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: ellipse(10px 0px at 0% 50%);
  background-color: #F0F0F0;
  transition: clip-path 0.5s ease-in-out;
  z-index: -1;
}

.selection-section .answers .selectHairCareOptionBtn:hover::after {
  background-color: #F0F0F0;
  clip-path: ellipse(110% 200% at 0% 50%);
} */

.selection-section .answers .selectHairCareOptionBtn svg {
  opacity: 0.2;
}

.selection-section .answers .inner-ans {
  display: flex;
  align-items: center;
}

[dir='rtl'] .selection-section .answers .inner-ans p{ 
  margin-right: 20px;
}

.selection-section .answers .inner-ans .type-img {
  width: 200px;
}

.selection-section .answers .inner-ans .type-img:dir(rtl) {
  transform: rotate(180deg);
}

.selection-section .answers .selectHairCareOptionBtn:nth-child(2) .inner-ans .type-img {
  margin-bottom: -8px;
}

.selection-section .answers .inner-ans p {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-left: 25px;
  margin-bottom: 2px;
  text-align: start;
  line-height: 1;
}

.selection-section footer p {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #666666;
}

.selection-section footer img {
  width: 198px;
}

.selection-section .answer-listDiv .answer-list {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
}

.selection-section .brushCountTitle {
  font-weight: 500;
  font-size: 24px;
  color: #131313;
  margin-bottom: 1px;
}

.selection-section .seeMoreDetailsText {
  font-size: 14px;
  color: #131313;
}

[dir='rtl'] .slick-prev {
  right: 0px !important;
  left: auto !important;
  transform:  rotate(180deg);
}

[dir='rtl'] .slick-next {
  right: auto !important;
  left: 0px !important;
  transform:  rotate(180deg);
}

[dir='rtl'] .slick-next:before {
  content: '→' !important;
}
 
[dir='rtl'] .slick-prev:before {
  content: '←' !important;
}

.offline-screen{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50%;
}

@media screen and (min-width:1500px) {
  .show-small-imgs {
    height: calc(100vh - 352px);
  }
}

@media screen and (min-width:992px) and (max-width:1500px){
  /* .selection-section .answers {
    max-height: calc(100vh - 52vh);
  } */
  .show-small-imgs {
    height: calc(100vh - 352px);
  }
}
@media screen and (max-width:1500px) {
  .hairComb-width {
    width: min(100% , 320px);
  }
} 

@media screen and (max-width:1200px) { 
  .hairComb-row {
    row-gap: 20px;
  }
  .selection-section .header {
    padding-block: 10px;
    margin-bottom: 10px;
  }

  .selection-section .header .title {
    font-size: 20px;
  }

  .changeLang .nav-link {
    padding: 0;
    margin-right: 20px;
  }

  .selection-section .description {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .selection-section .question {
    padding: 8px 0 8px; 
  }

  .selection-section .question h4 {
    font-size: 18px;
  }

  .main-contain .answer-listDiv {
    top: 40px;
  }

  .selection-section .answers {
    gap: 20px;
    padding-bottom: 20px;
  }

  .selection-section .answers .selectHairCareOptionBtn {
    min-height: 56px;
    padding: 0px 10px 0px 0;
  }

  .selection-section .answers .inner-ans .type-img {
    width: 160px;
  }

  .selection-section .answers .inner-ans p {
    font-size: 16px;
    margin-left: 20px;
    text-align: start;
  }

  .selection-section .brushCountTitle {
    font-size: 20px;
  }

  .selection-section .seeMoreDetailsText {
    font-size: 14px;
  }

}

@media screen and (max-width:991px) {
  .home .container-fluid .col-lg-4,
.col-xl-4,
.col-md-4 {
 overflow: hidden;
}
  /* .selection-section .answers {
    max-height: calc(100vh - 50vh);
  } */
  
  .center-img .hairComb-width {
    width: min(100% , 200px);
  }
  .hairComb-row:has(.center-img) {
    gap: 10px;
  }

  .modalImgDiv {
    display: none;
  }

  .selection-section {
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  }
  .show-small-imgs {
    height: calc(100vh - 352px);
  }
} 

/* slick slider */

.img-detail-box:focus-visible {
  outline: none;
}

.slick-next {
  right: 0 !important;
  background-color: #f0f0f0 !important;
  color: #000 !important;
  z-index: 2;
  padding: 10px 5px 10px 10px !important;
  width:40px !important;
  height: 40px !important;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.slick-prev {
  left: 0 !important;
  background-color: #f0f0f0 !important;
  color: #000 !important;
  z-index: 2;
  padding: 10px 10px 10px 5px !important;
  width:40px !important;
  height: 40px !important;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.slick-prev:before{
  color: #9E0022 !important;
  opacity: 1 !important;
  font-size: 25px !important;
}

.slick-next:before{
  color: #9E0022 !important;
  opacity: 1 !important;
  font-size: 25px !important;
}

/* title postion */
.title-postion{
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-postion h4{
  /* margin-top: calc(100vh - 72vh); */
  display: flex;
  align-items: flex-end;
  height: calc(100% - 55%);
  font-size: calc(1rem + 0vw);
  position: absolute;
}

.description-postion{
  position: relative;
  display: flex;
  justify-content: center;
  margin-right: calc(100vw - 90vw);
}

.description-postion h4{
  margin-top: calc(100vh - 135vh);
  font-size: calc(1rem + 0vw);
  position: absolute;
  color: #8c6e29;
}

.description-postion h3{
  margin-top: calc(100vh - 132vh);
  font-size: calc(1rem + 0vw);
  position: absolute;
  color: #8c6e29;
}

.btn-shop{
  background-color: #9E0022;
  color: #FFF;
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: min(35% , 160px);
  font-weight: 700;
}

html:lang(fr) .btn-shop{
  font-size: 14px;
}

a{
  text-decoration: none!important;
}

a:hover {
  text-decoration: none!important;
}

.bg-remove{
  background: none!important;
  border: none!important;
}

.btn-secondary:focus{
  box-shadow: none!important;
}

@media screen and (min-width:100px) and (max-width:450px) {
    .selection-section {
      gap: 20px;
    } 
}

.not-found h1{
  font-size: 200px;
  color: #9E0022;
}

.not-found h3{
  margin-bottom: 20px;
  font-size: 40px;
  text-transform: uppercase;
}